<template>
  <div class="p-1">
    <div class="flex justify-between items-center mb-2 flex-wrap">
      <h3 class="text-2xl">Secciones</h3>
      <a-button :to="{ name: 'create_section' }" v-if="user.isAdmin" class="w-full md:w-auto">
        Crear sección
      </a-button>
    </div>
    <a-table
      :columns="columns"
      :source="pagination.data"
      :loading="loading"
      toolbar-column-filter
      :pagination="pagination"
      @paginate="search({ page: $event })"
      @toolbar(refresh)="search({ page: 1 })"
      @delayed-filter:letter_code="search({ search: $event, page: 1 })"
      @delayed-filter:semester_number="search({ semester_number: $event, page: 1 })">
<!--      @toolbar(search)="search({ search: $event, page: 1 })">-->

      <template #td(actions)="{ item }">
        <a-dropdown>
          <template #menu>
            <a-dropdown-item :to="{ name: 'section', params: { section_id: item.id } }" v-if="user.isAdmin || (user.isCoordinator && item.specialization.id === user.teacher.specialization_id)">
              Ver sección
            </a-dropdown-item>
            <a-dropdown-item :to="{ name: 'create_section', query: { update: item.id } }" v-if="user.isAdmin">
              Actualizar
            </a-dropdown-item>
            <a-dropdown-item @click="deleteSemester(item.id)"  v-if="user.isAdmin">
              Eliminar
            </a-dropdown-item>
          </template>
        </a-dropdown>
      </template>

      <template #filter(specialization.title)>
        <v-select
          @clear="search({ page: 1 })"
          :clearable="true"
          :options="mappedSpecialities"
          :reduce="$0 => $0.value"
          placeholder="Especialidad"
          class="w-48"
          v-model="query.specialization"/>
      </template>

      <template #toolbar>
        <v-select
          :clearable="false"
          :options="mappedPeriods"
          :loading="loadingPeriods"
          :reduce="$0 => $0.value"
          placeholder="Período"
          class="w-48"
          v-model="query.period"/>
      </template>

      <template #empty>
        <h1 v-if="!period" class="text-center p-8 text-2xl font-medium">
          Por favor seleccione un período
        </h1>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  data: () => ({
    limit: 10,
    query: {
      limit: 10,
      with: 'period,specialization,semester,courses',
      search: '',
      specialization: '',
      semester: '',
      sort_by: 'letter_code',
      period: null
    },
    specializations: [],
    period: null,
    timeout: null,
    periods: [],
    loadingPeriods: false
  }),
  computed: {
    ...mapState({
      pagination: state => state.sections.pagination,
      spec: state => state.specializations.pagination,
      loading: state => state.sections.loading,
      user: state => state.session.user
    }),
    mappedPeriods() {
      return this.periods.map($0 => ({ label: `${$0.year}-${$0.number}`, value: $0.id }))
    },
    mappedSpecialities() {
      return this.spec.data.map($0 => ({ label: $0.title, value: $0.id }))
    },
    columns() {
      return [
        { title: 'Sección', key: 'letter_code', filter: true, preventLocalFilter: true, display: true },
        { title: 'Varones', key: 'males', mutate: ({ item }) => `${item.males_assigned} / ${item.males_limit}`, display: true },
        { title: 'Hembras', key: 'females', mutate: ({ item }) => `${item.females_assigned} / ${item.females_limit}`, display: true },
        { title: 'Especialidad', key: 'specialization.title', filter: true, preventLocalFilter: true, display: true },
        { title: 'Período', key: 'period', mutate: ({ key }) => `${key.year}-${key.number}`, display: true },
        { title: 'Semestre', key: 'semester.number', filter: true, preventLocalFilter: true, display: true },
        { title: '¿Completa?', key: 'filled', mutate: ({ key }) => key ? 'Si' : 'No',display: true },
        { title: 'Acciones', slot: 'actions', alignment: 'right', display: !this.user.isStudent && !this.user.isLowAdmin, columnFilterable: false }
      ].filter($0 => $0.display)
    }
  },
  methods: {
    ...mapActions({
      fetchSections: 'sections/fetchAll',
      fetchSpecializations: 'specializations/fetchSpecializations',
      deleteSemester: 'sections/delete'
    }),
    ...mapMutations({
      setNavbarTitle: 'SET_NAVBAR_TITLE'
    }),
    search(query) {
      this.fetchSections({ ...this.query, ...query })
    },
    fetchPeriods(query) {
      this.loadingPeriods = !this.loadingPeriods
      this.$repository.periods
        .index(query)
        .then(({ data }) => {
          this.periods = data
          this.query.period = this.periods.find((value) => value.enabled ?? value).id
        })
        .finally(() => this.loadingPeriods = !this.loadingPeriods)
    },
    fetchSpec () {
      this.fetchSpecializations()
        .finally(() => {
          this.specializations = this.spec.data.map($0 => ({ label: $0.title, value: $0.id }))
        })
    }
  },
  watch: {
    'query.period'(val) {
      if (val) {
        this.fetchSections({ ...this.query, page: this.$route.query.page || 1, period: val })
      }
    },
    'query.specialization'() {
        this.fetchSections({ ...this.query, page: 1 })
    }
  },
  mounted() {
    this.fetchPeriods({ limit: 0, sort_by: 'year,asc' })
    this.fetchSpec()
  }
}
</script>
